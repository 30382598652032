import $ from 'jquery'
import axios from 'axios'

$(() => {

  console.log('RewardFeedback loaded!', {env: process.env.NODE_ENV, envObj: process.env})
  $('body').append('<div id="rewardfeedback-app" class="RewardFeedback"/>')

  let apiPath = 'https://www.rewardfeedback.com' // prod
  let scriptPath = 'https://www.rewardfeedback.com' // prod
  if (process.env.NODE_ENV === 'development'){ // local
    apiPath = `http://localhost:8000`
    scriptPath = `http://localhost:8080`
  }

  let mount = async () => {
    let resp = await axios.get(`${apiPath}/api/versions`)
    let mountPath = resp.data.versions[`/mount.js`]
    if (typeof resp.data.versions[`/mount${process.env.NODE_ENV === 'development' ? '.hot-update' : ''}.js`] == undefined) {
      mountPath = resp.data.versions[`/mount${process.env.NODE_ENV === 'development' ? '.hot-update' : ''}.js`]
    }

    $('body').append(`<script type="text/javascript" src="${scriptPath}/${mountPath}">`)
  }

  mount()
})
